import React from "react";
import Head from "./Head";
import { HomeIcon } from "./IndexIcon";

export const Home = ({ condos, subHeading }) => {
  return (
    <React.Fragment>
      <div
        style={{
          backgroundColor: "#D5D5D5",
          minHeight: "100vh",
          padding: "15px 12px 12px"
        }}
      >
        <Head
          condo={condos.condoName}
          subHeading={subHeading || "Building Management System"}
        />
        <div className="main-root">
          <div className="main-container" style={{ margin: 0, padding: 0 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: 70
              }}
            >
              <HomeIcon style={{ marginTop: "20px" }} />
            </div>

            <h1
              style={{
                textAlign: "center",
                color: "rgb(50, 129, 124)",
                margin: "-20px 60px 80px",
                lineHeight: "42px"
              }}
            >{`Welcome to the ${condos.condoName || "Smart Condo"} `}</h1>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
