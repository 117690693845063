import React from "react";
import PropTypes from "prop-types";
import {
  CssBaseline,
  Paper,
  Typography,
  Grid,
  FormControl
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  main: {
    width: "auto",
    display: "block",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 3}px`,
    overflowY: "scroll"
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  }
});

function MyPaper(props) {
  const { classes } = props;
  return (
    <main
      className={classes.main}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        ...props.style
      }}
    >
      <CssBaseline />
      <Grid
        container
        sm={12}
        md={6}
        lg={6}
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "92.6vh"
        }}
      >
        <FormControl margin="normal" fullWidth>
          <Paper
            className={classes.paper}
            style={{ overflowY: props.overFlow ? "scroll" : "hidden" }}
          >
            {props.title && (
              <Typography
                variant="h6"
                style={{ marginTop: 16, textAlign: "center", color: "#5575e6" }}
              >
                <div>
                  {props.img && (
                    <div>
                      <img src={props.img} width="250px" alt="invite_image" />
                    </div>
                  )}
                  <div style={{ fontSize: "20px" }}>{props.title} </div>
                </div>
              </Typography>
            )}
            {!!props.subtitle && (
              <Typography
                style={{
                  marginTop: 16,
                  marginBottom: 16,
                  textAlign: "center",
                  fontSize: "15px",
                  color: "#5575e6"
                }}
              >
                {props.subtitle}
              </Typography>
            )}
            {props.children}
          </Paper>
        </FormControl>
      </Grid>
    </main>
  );
}

MyPaper.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};

export default withStyles(styles)(MyPaper);
