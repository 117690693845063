import React from "react";
import SubmitButton from "../../components/SubmitButton";
import { Checkbox, FormControlLabel, Card } from "@material-ui/core";
const SubscribeFormDesign = ({ handleChange, onSubmit, checked }) => {
  return (
    <React.Fragment>
      <div className="main-root" style={{ backgroundColor: "#ffffff" }}>
        <div className="main-container" style={{ backgroundColor: "#ffffff" }}>
          <Card
            style={{ width: "250px", padding: "15px", textAlign: "center" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  color="primary"
                />
              }
              label="Notification"
            />
          </Card>
          <SubmitButton onClick={onSubmit} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default SubscribeFormDesign;
