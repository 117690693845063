import React from "react";
import Form from "./views/Subscribe/Form";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { getCookie } from "./helpers";
import { Home } from "./components/HomePage";
import PageNotFound from "./components/ErrorPage";
import { listCompanyData } from "./api/visit";
import AlertDetails from "./views/AlertDetails";
// import ExpressEntry from "./views/ExpressEntry";
// import QRInvitations from "./views/Invitation";
// import EmployeeEntry from "./views/EmployeeEntry/EmployeeEntry";
// import Invitation from "../src/views/webInvite/invitation";
// import EmployeeRegister from "views/EmployeeRegister/EmployeeRegister";
// import Invites from "./components/Invite";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      condoName: "M P A",
      location: "https://www.mpa.com/",
      aliasName: "mpa",
      errorPage: false,
      loader: true,
    };
  }

  componentDidMount = () => {
    this.setState({ loader: false });
  };

  fetchBlock = () => {
    listCompanyData("", getCookie("vms_token"))
      .then(({ data }) => {
        let units = data.map((value) => ({
          label: value.name,
          value: value.name,
        }));
        this.setState({ blockList: units, loader: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loader: false, errorPage: true });
      });
  };

  render() {
    const { condoName, location, aliasName, errorPage, loader } = this.state;
    const about = { condoName, location, aliasName };
    if (loader) {
      return <div id="loader" />;
    }

    if (errorPage) {
      return <PageNotFound condos={about} />;
    }
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route
              path="/alerts/:id/details"
              render={(props) => {
                return <AlertDetails {...props} about={about} />;
              }}
              exact
            />
            {/* <Route
              path="/express-entry"
              render={(props) => {
                let query = new URL(window.location.href).searchParams.get(
                  "token"
                );
                if (query) {
                  setCookie("vms_token", query, 60);
                  this.fetchBlock();
                  return <Redirect to={`/express-entry`} />;
                } else {
                  return (
                    <ExpressEntry
                      {...props}
                      about={about}
                      condos={about}
                      subHeading={"Smart QR Invite"}
                    />
                  );
                }
              }}
              exact
            />
            <Route
              path="/employees/registration"
              render={(props) => {
                let query = new URL(window.location.href).searchParams.get(
                  "token"
                );
                if (query) {
                  setCookie("vms_token", query, 60);
                  this.fetchBlock();
                  return <Redirect to={`/employees/registration`} />;
                } else {
                  return (
                    <EmployeeRegister {...props} about={about} condos={about} />
                  );
                }
              }}
              exact
            />
            <Route
              path="/employees/:id/details"
              render={(props) => {
                return <EmployeeEntry {...props} about={about} />;
              }}
              exact
            />
            <Route
              path="/residents/subscribe"
              render={(props) => <Form {...props} about={about} />}
              exact
            />
            <Route
              path="/invitations/contractors/:invite_id/update"
              render={(props) => <Invites {...props} about={about} />}
              exact
            />

            <Route
              path="/invitations/new"
              render={(props) => {
                return (
                  <Invitation
                    {...props}
                    condos={about}
                    subHeading={"Smart QR Invite"}
                  />
                );
              }}
              exact
            />

            <Route
              path="/invitations/:invitee_id"
              render={(props) => {
                return <QRInvitations {...props} about={about} />;
              }}
              exact
            /> */}

            <Route
              path="/home"
              render={(props) => <Home {...props} condos={about} />}
            />

            <Route to="*">
              <Redirect to="/home" />
            </Route>
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
