import React, { Component } from "react";
import { fetchDetails } from "../../api/resident";
import SubscribeForm from "./SubscribeForm";
import Mypaper from "../../components/MyPaper";
import FormDesign from "./FormDesign";
import Head from "../../components/Head";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resident_data: "",
      block: "",
      floor: "",
      unit: "",
      phone: "",
      visible: false,
      submited: false,
      loading: false,
      message: ""
    };
  }
  params = () => {
    const { block, floor, unit, phone } = this.state;
    return {
      block,
      floor,
      unit,
      phone
    };
  };

  fetchResidentData = e => {
    e.preventDefault();
    this.setState({ loading: true });
    fetchDetails(this.params())
      .then(data => {
        this.setState({
          resident_data: data.data,
          submited: true,
          loading: false
        });
      })
      .catch(e => {
        this.setState({
          visible: true,
          submited: false,
          loading: false,
          message: e[1].message
        });
      });
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      block,
      floor,
      unit,
      visible,
      phone,
      submited,
      resident_data,
      loading,
      message
    } = this.state;
    const { handleChange, fetchResidentData } = this;
    const FormDesignProps = {
      block,
      floor,
      unit,
      visible,
      phone,
      loading,
      handleChange,
      fetchResidentData,
      message
    };
    return (
      <React.Fragment>
        <Head
          condo={this.props.about.condoName}
          subHeading={"Resident Subscribe Form"}
        />
        <Mypaper
          title={`Hi ${(submited && resident_data.name && resident_data.name) ||
            `there`}, Notification subscription......!`}
        >
          {submited && submited ? (
            <SubscribeForm data={resident_data} about={this.props.about} />
          ) : (
            <FormDesign {...FormDesignProps} />
          )}
        </Mypaper>
      </React.Fragment>
    );
  }
}
export default Form;
