import React from "react";
import Head from "./Head";
import { useMediaQuery } from "@material-ui/core";

import { NotFound } from "./IndexIcon";

const styles = {
  image: {
    width: 330,
    height: 185,
    margin: 40
  },
  image_min: {
    width: 220,
    height: 150,
    margin: 20
  },
  content: {
    fontFamily: "Montserrat",
    lineHeight: "34px",
    fontWeight: "bold",
    fontSize: 18,
    textAlign: "center",
    margin: "10px 15px"
  }
};

export const PageNotFound = props => {
  const {
    condoName,
    data: { title, message },
    head: { subHeading }
  } = props;
  const matches = useMediaQuery("(max-width:600px)");

  return (
    <React.Fragment>
      {/* <div
        style={{
          backgroundColor: "#D5D5D5",
          height: "100%",
          padding: "15px 12px 15px"
        }}
      > */}
      <div
        style={{
          backgroundColor: "#D5D5D5",
          minHeight: "100vh",
          padding: "15px 12px 12px"
        }}
      >
        <Head condo={condoName || ""} subHeading={subHeading} />
        <div className="main-root">
          <div className="main-container" style={{ margin: 0, padding: 0 }}>
            <div
              style={{
                paddingBottom: 130,
                position: "relative",
                margin: "0px 15px"
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: 50
                }}
              >
                <NotFound style={matches ? styles.image_min : styles.image} />
              </div>
              <h1 style={{ ...styles.content, fontSize: 20 }}>{title}</h1>
              <h1 style={{ ...styles.content, fontWeight: 100 }}>{message}</h1>
            </div>
            <span
              style={{
                backgroundImage: `url(${require("../assets/img/base_border.svg")})`,
                backgroundRepeat: "repeat-x",
                // position: "absolute",
                width: "100%",
                display: "block",
                // bottom: -8,
                height: 15,
                left: 0,
                marginBottom: "-9px"
              }}
            />
          </div>
        </div>

        {/* </div> */}
      </div>
    </React.Fragment>
  );
};
export default PageNotFound;
