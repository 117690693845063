import React from "react";
const Head = (props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <header className="header">
        <div>
          <img
            src={require("../assets/img/logo.png")}
            width="100px"
            alt="logo"
          />
          <h3
            style={{
              fontSize: 20,
              textAlign: "center",
              color: "#32817c",
              margin: "0px",
            }}
          >
            {props.subHeading}
          </h3>
        </div>
      </header>
    </div>
  );
};
export default Head;
