import moment from "moment";

function getCookie(name) {
  let cookie = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return cookie && cookie[2];
}

function setCookie(name, value, days = 0, hours = 0) {
  let date = new Date();
  if (days || hours) {
    date.setTime(
      date.getTime() + hours * 60 * 60 * 1000 + 24 * 60 * 60 * 1000 * days
    );
    document.cookie =
      name + "=" + value + ";path=/;expires=" + date.toGMTString();
  } else {
    document.cookie = name + "=" + value;
  }
}

function popCookie(name) {
  let value = getCookie(name);
  deleteCookie(name);
  return value;
}

function deleteCookie(name) {
  setCookie(name, "", -1);
}

const jsonHeaders = {
  Accept: "application/json",
  "Content-type": "application/json",
};

function titleize(str) {
  if (!str) {
    return str;
  }

  return str
    .split(" ")
    .map(function(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    })
    .join(" ")
    .split("_")
    .map(function(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    })
    .join(" ");
}

function presence(string) {
  if (string === "undefined") {
    return "-";
  }
  return string ? string : "-";
}

export const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
};

function formatTime(time, format) {
  if (!time) return "-";

  format = format || "DD-MM-YYYY HH:mm:ss";
  return moment(time).format(format);
}

export {
  getCookie,
  jsonHeaders,
  setCookie,
  popCookie,
  deleteCookie,
  titleize,
  presence,
  formatTime,
};
