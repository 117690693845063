import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { FormControl, TextField, useMediaQuery } from "@material-ui/core";
import { color } from "../config.js";
import {
  KeyboardTimePicker,
  KeyboardDatePicker,
  DateTimePicker
} from "@material-ui/pickers";

export const styles = {
  input: {
    width: 330,
    // marginBottom: 15,
    // marginRight: 10,
    margin: "0px 12px 5px"
  },
  input_min: {
    // marginRight: 10,
    margin: "0px 10px 5px 0px"
  },
  otp_box: {
    width: 340
  },
  inputText: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 20,
    color: "#404040"
  }
};

const wrapper = Comp =>
  withStyles({
    root: {
      " & .MuiInputBase-root": {
        borderRadius: "9px",
        borderColor: "rgba(0, 0, 0, 0.49)",
        backgroundColor: "#fff"
      },

      "& label.Mui-focused": {
        color: "#dedede",
        paddingRight: 20,
        marginLeft: 5,
        backgroundColor: "#fff"
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#dedede"
      },
      "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
          borderColor: "#dedede"
        },
        "&.Mui-focused fieldset": {
          borderColor: "#dedede"
        }
      }
    }
  })(Comp);

export const CustomTextField = wrapper(TextField);
export const CustomFormControl = wrapper(FormControl);
export const CustomDatePicker = wrapper(KeyboardDatePicker);
export const CustomTimePicker = wrapper(KeyboardTimePicker);
export const CustomDateWithTimePicker = wrapper(DateTimePicker);

export const SimpleTextField = props => {
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <CustomTextField
      variant="outlined"
      style={matches ? styles.input : styles.input_min}
      InputLabelProps={{
        style: {
          color,
          fontFamily: "Montserrat",
          fontSize: 14,
          fontWeight: 300
        }
      }}
      inputProps={{
        style: styles.inputText,
        autoComplete: "off"
      }}
      className="text-field"
      {...props}
    />
  );
};

export const SimplePhoneField = props => {
  return (
    <SimpleTextField
      inputProps={{
        maxLength: 10,
        minLength: 8,
        inputMode: "tel",
        pattern: "[0-9]*",
        autoComplete: "off",
        style: styles.inputText
      }}
      {...props}
    />
  );
};

export const SimpleOTPField = props => {
  return (
    <SimpleTextField
      inputProps={{
        maxLength: 4,
        inputMode: "tel",
        autoComplete: "off",
        style: {
          textAlign: "center",
          letterSpacing: "21px",
          fontFamily: "Montserrat",
          fontWeight: 500,
          fontSize: 20,
          color: "#4A4A4A"
        }
      }}
      style={{ ...styles.otp_box }}
      {...props}
    />
  );
};
